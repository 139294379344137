import React from "react"

export const download = <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.0002 16.332L4.3335 9.66537L6.20016 7.73203L9.66683 11.1987V0.332031H12.3335V11.1987L15.8002 7.73203L17.6668 9.66537L11.0002 16.332ZM3.00016 21.6654C2.26683 21.6654 1.63905 21.4043 1.11683 20.882C0.594607 20.3598 0.333496 19.732 0.333496 18.9987V14.9987H3.00016V18.9987H19.0002V14.9987H21.6668V18.9987C21.6668 19.732 21.4057 20.3598 20.8835 20.882C20.3613 21.4043 19.7335 21.6654 19.0002 21.6654H3.00016Z" fill="white"/>
</svg>

export const zoom = <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.6665 6.9987V2.9987H20.6665V0.332031H24.6665C25.3998 0.332031 26.0276 0.593142 26.5498 1.11536C27.0721 1.63759 27.3332 2.26536 27.3332 2.9987V6.9987H24.6665ZM0.666504 6.9987V2.9987C0.666504 2.26536 0.927615 1.63759 1.44984 1.11536C1.97206 0.593142 2.59984 0.332031 3.33317 0.332031H7.33317V2.9987H3.33317V6.9987H0.666504ZM20.6665 21.6654V18.9987H24.6665V14.9987H27.3332V18.9987C27.3332 19.732 27.0721 20.3598 26.5498 20.882C26.0276 21.4043 25.3998 21.6654 24.6665 21.6654H20.6665ZM3.33317 21.6654C2.59984 21.6654 1.97206 21.4043 1.44984 20.882C0.927615 20.3598 0.666504 19.732 0.666504 18.9987V14.9987H3.33317V18.9987H7.33317V21.6654H3.33317ZM5.99984 16.332V5.66536H21.9998V16.332H5.99984ZM8.6665 13.6654H19.3332V8.33203H8.6665V13.6654Z" fill="white"/>
</svg>

export const noRecordFound = require("../assets/no-record-found.png")

export const imageNoFound = require("../assets/image.png")
export const audioNoFound = require("../assets/audio.png")
export const videoNoFound = require("../assets/video.png")
export const applicationNoFound = require("../assets/application.png")