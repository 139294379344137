import React from "react";

import {
  Container,
  Typography,
  // Customizable Area Start
  Grid,
  Paper,
  Snackbar,
  Badge
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Header from '../../../components/src/Header.web';
import ProfileSetting from "./components/ProfileSetting.web";
import AccountSetting from "./components/AccountSetting.web";
import "../assets/index.scss";
import MuiAlert from "@material-ui/lab/Alert";
import { t } from "i18next";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// Customizable Area End

import Settings5Controller, {
  Props,
  configJSON,
} from "./Settings5Controller";

export default class Settings5 extends Settings5Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div>
        <Header
          type={"Settings"}
          onSearch={() => { }}
          navigation={this.props.navigation}
          profileImage={this.state.userInfo.photo}
        />
        <div style={{ background: "linear-gradient(to bottom, rgba(231,239,252,1) 40%, rgba(255,255,255,1) 100%)", width: '100%', height: '100%' }}>
          <Grid container className="edit_profile_new">
            <Grid container spacing={2}>
              <Grid className="nav-box" item xs={12} lg={3} sm={12} md={3}>
                <div className="nav-content">
                  <div data-test-id="profile-tab" onClick={(e) => this.handleActiveTab('profile')} className="border-title title-box">
                    <Typography className={this.state.activePage === 'profile' ? "nav-active-title" : "nav-title"}>{t('Edit Profile')}</Typography>
                  </div>
                  <div data-test-id="account-tab" onClick={(e) => this.handleActiveTab('account')} className="title-box">
                    <Typography className={this.state.activePage === 'account' ? "nav-active-title" : "nav-title"}>{t('Account settings')}</Typography>
                  </div>
                </div>
              </Grid>
              <Grid className="form-box" item xs={12} lg={9} sm={12} md={9}>
                <div className="nav-responsive-content">
                  <Grid spacing={2} container>
                    <Grid data-test-id="profile-tab-2" onClick={(e) => this.handleActiveTab('profile')} item xs={6}>
                      <Typography className={this.state.activePage === 'profile' ? "nav-active-title" : "nav-title"}>{t('Edit Profile')}</Typography>
                    </Grid>
                    <Grid data-test-id="account-tab-2" onClick={(e) => this.handleActiveTab('account')} item xs={6}>
                      <Typography className={this.state.activePage === 'account' ? "nav-active-title" : "nav-title"}>{t('Account settings')}</Typography>
                    </Grid>
                  </Grid>
                </div>
                <Paper className="paper-box">
                  {
                    this.state.activePage === 'profile' ?
                      <div>
                        <div className="edit_profile_back_container">
                          <Badge style={{cursor: 'pointer'}} data-test-id='back-arrow-icon' onClick={()=>{this.handleNavigationBack()}}>
                            <ArrowBackIcon
                              className="arrow_button"
                            ></ArrowBackIcon>
                          </Badge>
                          <Typography
                            variant="subtitle2"
                            className="edit_profile_back_text"
                            data-test-id='back-arrow-text'
                            onClick={()=>{this.handleNavigationBack()}}
                          >{t("Edit Profile")}</Typography>
                        </div>
                        <ProfileSetting
                          state={this.state}
                          handleChange={this.handleChange}
                          handleSubmit={this.handleProfileSubmit}
                          handleSelectInterest={this.handleSelectInterest}
                          handleAddFormFields={this.handleAddFormFields}
                          handleOtherFields={this.handleOtherFields}
                          handleRemoveAddMoreFields={this.handleRemoveAddMoreFields}
                          handleShowFields={this.handleShowFields} 
                          handleOASText={this.handleOASText}
                          handleSEText={this.handleSEText}
                          handleImageSelect={this.handleImageSelect}
                          handleDropdownChange={this.handleDropdownData}
                          deleteInterestsData={this.deleteInterestsData}
                          handleAddNewInterest={this.handleAddNewInterest}
                          handleAddToUserInterest={this.handleAddToUserInterest}
                          handleDeleteExistingInterest={this.handleDeleteExistingInterest}
                          handleAddExistingInterest={this.handleAddExistingInterest}
                          deleteSkillsData={this.deleteSkillsData}
                          handleAddToUserSkill={this.handleAddToUserSkill}
                          handleAddExistingSkill={this.handleAddExistingSkill}
                          handleDeleteExistingSkill={this.handleDeleteExistingSkill}
                          handleAddNewSkill={this.handleAddNewSkill}
                          handleConnectionList={this.handleConnectionList}
                          handlePhoneNumberChange={this.handlePhoneNumberChange}
                          handleOpenInterestModal={this.updateInterestData}
                          handleOpenSkillModal={this.updateSkillsData}
                        />
                      </div> :
                      <AccountSetting handleShowConfirmPassword={this.handleShowConfirmPassword} handleShowChangePassword={this.handleShowChangePassword} handleShowCurrentPassword={this.handleShowCurrentPassword} state={this.state} handleChange={this.handlePasswordChange} handleSubmit={this.handlePasswordSubmit} />
                  }
                </Paper>
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={this.state.showSnackbar}
                  autoHideDuration={5000}
                  onClose={this.handleCloseSnackbar}
                  className="snack"
                >
                  <MuiAlert
                    onClose={this.handleCloseSnackbar}
                    severity={this.state.severity}
                  >
                    {this.state.message}
                  </MuiAlert>
                </Snackbar>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
